
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field } from "vee-validate";
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import ModalDownloadInvoice from "@/components/modals/download/modalDownloadInvoice.vue";
moment.locale("id");
const router = useRouter();
interface Book {
  detail: any;
  invoice: any;
  bankName: string;
  metodeName: string;
  sumberdana: string;
  nomorRekening: string;
  addInvoices: any;
  preview: any;
}
interface Send {
  data: any;
}
export default defineComponent({
  name: "invoices",
  components: {
    Field,
    Loading,
    Multiselect,
    ModalDownloadInvoice,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      book: {
        detail: <any>[],
        invoice: <any>[],
        bankName: "",
        coaCode: "",
        sumberdana: "",
        sumberdanaa: "",
        coa: "Topup Manual",
        saldo_sumberdana: 0,
        metodeName: "Otomatis",
        nomorRekening: "",
        addInvoices: <any>[],
        preview: <any>[],
      } as Book,
      send: {
        data: <any>[],
      } as Send,
      tableData: <any>[],
      ListSchool: <any>[],
      ListMetode: [
        {
          label: "Otomatis",
          value: "otomatis",
        },
        {
          label: "Manual",
          value: "manual",
        },
      ],
      listcoa: <any>[],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      wordCurrency: "",
      totalInvoice: "",
      code: "",
      names: "",
      amount: 0,
      discount: 0,
      tax: 0,
      showFormCoa: false,
      balanceCoa: 0,
      shipping: 0,
      totalAmount: 0,
      totalPaid: "",
      totalUnPaid: "",
      listbankManual: <any>[
        {
          initial: "BNI",
          no: "7000040408",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/BNI.svg",
        },
        {
          initial: "Jatim Syariah",
          no: "6201000466",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/bank_jatim_syariah.png",
        },
        {
          initial: "BSI",
          no: "8882609178",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/bsi.svg",
        },
        {
          initial: "NTBS",
          no: "0010100163276",
          name: "PT Teknologi Kartu Indonesia",
          icon: "/media/bank/NTBS.svg",
        },
      ],
      bankManual: "",
      sumberdana: "",
      sumberdanaa: "",
      minJtp: "",
      schoolId: "",
      companyId: "",
      schoolName: "",
      schoolSelected: false,
      invoiceDate: "",
      titip: <any>[],
      checkedItem: <any>[],
      detail: <any>[],
      catatan:
        "Status pembayaran akan berubah secara otomatis setelah invoice terbayar",
      konfirm: true,
      paymentStatus: "UNPAID",
      viewInvoices: <any>{},
      date: "",
      Idinvoice: "",
      InvoicesById: <any>[],
      btnBayar: true,
      statusBayar: true,
      doSubmit: true,
      error1: {
        selectBank1: "",
      },
      error2: {
        selectBank2: "",
      },
      note: "-",
      title: "",
      number: "",
      itemType: "",
      invoiceYear: "",
      targetKonfirmasi: {
        id: "",
        namaPengirim: "",
        rekTujuan: "",
        persetujuanFinance: "",
        rekPengirim: "",
        tanggal: "",
        note: "",
        paymentId: "",
        noRef: "",
        penanggungJawab: "",
        namaRektujuan: "",
        paidAmount: 0,
        imageBuktiTF: "",
        imageBuktiTFRslt: "",
      },
      errorBayarManual: {
        noRef: "",
        penanggungJawab: "",
        imageBuktiTF: "",
        namaPengirim: "",
        tanggal: "",
      },
      totalamount: 0,
      fileInputKey3: 0,
      imageBuktiTFDisplay: "",
      listBank: <any>[],
      listBankOtomatis: <any>[],
      listBankManual: <any>[],
      modalpaymentByID: "",
      tipeBayarManualSingle: false,
      buttonBayarManualSingle: false,
      resultBayarManual: false,
      successBayarManual: false,
      abc: "<div>aaaaa</div>",
      statusModalBayarSinglePaid: false,
      poolings: false,
      statusModalBayarSingleVa: false,
      previewPembayaranSingle: false,
      showVaName: "",
      showVaBank: "",
      showVaNumber: "",
      showExpVaNumber: "",
      paymentStatusInit: false,
      showtutorial: false,
      role: "",
      name_user: "",
      id_user: "",
      schoolid2: "",
      tutorial: "",
      alertChange: "Metode Pembayaran Berhasil Dipilih",
      aksesBayarGabung: false,
      aksesBayar: false,
      aksesDelete: false,
      deleteByStatus: false,
      filter: false,
      sumber: "",
      showFilter: false,
      timer: 0,
      timerComboSchool: 0,
      loadingComboSchool: false,
      dataPayment: <any>{
        paymentId: "",
        paymentMethod: "",
        paymentStatus: "",
        vaName: "",
        vaBank: "",
        vaNumber: "",
        showExpVaNumber: "",
        item: "",
      },
      checkDataPayment: <any>{},
      expDate: 0,
      invoiceNumber: "",
      invoiceType: "",
      salesName: "",
      province: <any>[],
      listInvoiceType: <any>[],
      listSalesName: <any>[],
      listProvince: <any>[],
      previewDatas: <any>[],
      selected: <any>[],
      selectedId: <any>[],
      selectAll: false,
      dateAll: ["", ""],
      startDate: "",
      endDate: "",
      collapse: {},
      collapsell: false,
      mergeData: <any>{},
      collapsed: [false],
      collapsedAll: false,
      listResume: [
        {
          label: "Total Invoice",
          img: "total-invoice",
          field: "invoiceCount",
        },
        {
          label: "Belum Dibayarkan",
          img: "belum-dibayarkan",
          field: "unpaidCount",
        },
        {
          label: "Jatuh Tempo",
          img: "jatuh-tempo",
          field: "countExpired",
        },
        {
          label: "Total Tagihan",
          img: "total-tagihan",
          field: "totalUnPaid",
          isCurrency: true,
        },
      ],
      resume: {},
      modalOpen: false,
      joinInvoice: false,
    };
  },
  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.id_user = JSON.parse(localStorage.getItem("user_account")!).userId;
    this.name_user = JSON.parse(localStorage.getItem("user_account")!).name;
    const route = useRoute();
    if (route.query.type) this.itemType = route.query.type.toString();
    this.getLocalStorage();
    if (localStorage.getItem("sekolahIdMenuInvoice"))
      this.schoolSelected = true;
    this.schoolId = localStorage.getItem("sekolahId") || "";
    this.schoolName = localStorage.getItem("schoolName") || "";
    if (this.schoolId !== "") this.getcombocoa(this.schoolId);
    if (this.schoolName) this.getListSchool(this.schoolName);
    this.cekRoleAkses();
    if (route.query.schoolId) this.schoolId = route.query.schoolId.toString();
    this.getComboInvoiceType();
    this.getComboProvince();
    this.getComboSales();
    this.getData(this.paging.size, this.paging.page);
    this.getListSchool();
    this.getListBank("AUTOMATIC", "");
    this.getListBank("MANUAL", "");
    setCurrentPageBreadcrumbs("Invoices", ["Data"]);
    window.addEventListener("keypress", (e) => {
      if (e.keyCode == 13) {
        this.doSubmit == false;
        e.preventDefault();
        return false;
      }
    });
  },
  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound)
        highBound = this.paging.totalElements;
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },
  methods: {
    getListBank(e, va) {
      ApiService.getWithoutSlug(`crmv2/main_bank/bank/payment/type/${e}`)
        .then((resp) => {
          if (e == "AUTOMATIC") this.listBankOtomatis = resp.data;
          else this.listBankManual = resp.data;
          this.listBank = [...this.listBankOtomatis, ...this.listBankManual];
        })
        .catch((err) => {
          this.listBank = [];
        });
    },
    getTutorial(sd) {
      var a = "";
      this.listBank.forEach((element: any) => {
        // if (this.book.metodeName == "Manual")
        if (element.bankName == sd) a = element.tutorial;
        else if (element.bankCode == sd) a = element.tutorial;
      });
      if (a == "" || a == null) this.showtutorial = false;
      else this.showtutorial = true;
      this.tutorial = a;
    },
    async cekStatus(e, data) {
      console.log(data);

      this.dataPayment.paymentId = data.paymentId || "";
      this.dataPayment.paymentMethod = data.paymentMethod || "";
      this.dataPayment.vaName = data.vaName || "";
      this.dataPayment.vaBank = data.vaBank || "";
      this.dataPayment.vaNumber = data.vaNumber || "";
      this.showExpVaNumber = data.expiredDate || "";
      this.totalAmount = data.totalAmount || "";
      this.schoolid2 = data.schoolId || "";
      this.joinInvoice = data.joinInvoice;
      this.sumber = data.item;
      if (data.paymentStatus == "PAID") this.paymentStatusInit = true;
      var urlrole = "crmv2/main_invoice/invoice/office/invoice";
      if (
        !(
          this.role == "SUPER_ADMIN" ||
          this.role == "SALES_AREA" ||
          this.role == "SALES_LEADER"
        )
      )
        urlrole = "crmv2/main_invoice/invoice/invoice";
      try {
        let responseWait = await ApiService.getWithoutSlug(
          `${urlrole}/wait/${e}`
        );
        if (responseWait.data == true) {
          var modal = new Modal(document.getElementById("kt_modal_menunggu"));
          modal.show();
        } else {
          let responseData = await ApiService.getWithoutSlug(
            `${urlrole}/payment_data/${e}`
          );
          this.checkDataPayment = responseData.data;
          this.getcombocoa(data.schoolId);
          if (this.checkDataPayment.paymentMethod)
            this.dataPayment.paymentMethod =
              this.checkDataPayment.paymentMethod;
          this.Idinvoice = data._id;
          this.dataPayment.paymentStatus = this.checkDataPayment.paymentStatus;
          if (this.checkDataPayment.paymentMethod == "MANUAL") {
            this.book.metodeName = "Manual";
            this.getTutorial(responseData.data.data.vaBank);
            var init = "";
            this.listBank.forEach((element: any) => {
              if (element.bankCode == responseData.data.data.vaBank) {
                init = element.bankAccountName;
                this.targetKonfirmasi.rekTujuan = element.bankAccountNumber;
                this.targetKonfirmasi.namaRektujuan = init;
              }
            });
            this.targetKonfirmasi.paidAmount =
              responseData.data.data.totalAmount;
            this.targetKonfirmasi.id = responseData.data.data.invoiceId;
            this.targetKonfirmasi.paymentId = responseData.data.data.paymentId;
            this.Idinvoice = responseData.data.data.invoiceId;
          }
          if (this.checkDataPayment.paymentMethod == "AUTOMATIC") {
            this.dataPayment.vaName = this.checkDataPayment.data?.vaName || "";
            this.dataPayment.vaBank = this.checkDataPayment.data?.vaBank || "";
            this.dataPayment.vaNumber =
              this.checkDataPayment.data?.vaNumber || "";
            this.targetKonfirmasi.namaRektujuan = responseData.data.data.vaName;
            this.book.bankName = responseData.data.data.vaBank;
            this.targetKonfirmasi.rekTujuan = responseData.data.data.vaNumber;
            this.Idinvoice = responseData.data.data.invoiceId;
            this.getTutorial(data.vaBank);
            this.dataPayment.item = data.item;
            await this.bayarPerInvoiceId(data);
          }
          if (data.joinInvoice && !data.vaBank) {
            this.book.metodeName = "Manual";
            this.dataPayment.paymentMethod = null;
          }
          var modal = new Modal(document.getElementById("kt_modal_2"));
          modal.show();
        }
      } catch (error) {}
    },
    cekRoleAkses() {
      switch (this.role) {
        case "SUPER_ADMIN":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = true;
          break;
        case "ADMIN_FINANCE":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = true;
          break;
        case "SALES_LEADER":
          this.aksesBayarGabung = true;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "SALES_AREA":
          this.aksesBayarGabung = true;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "CS_LEADER":
          this.aksesBayarGabung = true;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "CS_MEMBER":
          this.aksesBayarGabung = true;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "IMPLEMENTATOR_LEADER":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = false;
          break;
        case "IMPLEMENTATOR_MEMBER":
          this.aksesBayarGabung = true;
          this.aksesBayar = true;
          this.aksesDelete = false;
          break;
        case "MARKETING_LEADER":
          this.aksesBayarGabung = true;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
        case "MARKETING_MEMBER":
          this.aksesBayarGabung = true;
          this.aksesBayar = false;
          this.aksesDelete = false;
          break;
      }
    },
    changeMetode(prop) {
      var metode = prop.target.value == "Manual" ? "MANUAL" : "AUTOMATIC";
      this.bankManual = "";
      this.book.bankName = "";
    },
    resetMergeData() {
      this.mergeData = { amount: 0, discount: 0, tax: 0, totalAmount: 0 };
    },
    async previewData() {
      const toast = useToast();
      if (!this.schoolId) return toast.warning("Pilih sekolah terlebih dahulu");
      let query: any = [];
      query.push(`schoolId=${this.schoolId || ""}`);
      if (this.dateAll) {
        query.push(`fromDate=${this.dateAll[0]}`);
        query.push(`toDate=${this.dateAll[1]}`);
      }
      try {
        let response = await ApiService.getWithoutSlug(
          `crmv2/main_invoice/invoice/office/invoice/join_invoice/find?${query.join(
            "&"
          )}`
        );
        this.previewDatas = response.data;
        this.selected = [...response.data];
        this.selectedId = response.data.map((el: any) => el._id);
        this.collapsed = response.data.map((el: any) => false);
        this.selectAll = response.data.length > 0;
        await this.getPreviewData();
      } catch (error) {}
    },
    collapseAll() {
      this.collapsedAll = !this.collapsedAll;
      if (this.collapsedAll)
        this.collapsed = this.collapsed.map((el: any) => true);
      else this.collapsed = this.collapsed.map((el: any) => false);
    },
    addPreview() {
      this.book.bankName = "";
      this.selectBank();
      if (this.book.detail.length < 2) {
        this.btnBayar = false;
        this.statusBayar = true;
        if (this.book.detail.length == 0) this.statusBayar = false;
      } else {
        this.btnBayar = true;
        this.isLoading = true;
        var today = new Date();
        var date = `${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}`;
        this.send.data = {
          invoiceDate: date,
          detail: [],
        };
        this.book.detail.forEach((element: any) => {
          element["detail"].forEach((ifile) => {
            this.send.data["detail"].push(ifile);
          });
        });
        ApiService.postWithData(
          `crmv2/main_invoice/invoice/office/invoice/preview?schoolId=${this.schoolId}&title=Tagihan&itemType=Package`,
          this.send.data
        )
          .then((res) => {
            this.wordCurrency = res.data.wordCurrency;
            this.totalAmount = res.data.totalAmount;
            this.amount = res.data.amount;
            this.discount = res.data.discount;
            this.tax = res.data.tax;
            this.shipping = res.data.shipping;
            this.book.invoice = res.data;
            this.isLoading = false;
            this.mergeData = res.data;
          })
          .catch((e) => {
            this.isLoading = false;
          });
      }
    },
    submitAddInvoices() {
      Swal.fire({
        title: "Konfirmasi",
        html: `Apakah anda yakin akan melakukan pembayaran ${
          this.mergeData?.detail?.length
        } Tagihan Sekolah <b>${
          this.mergeData?.schoolName
        }</b> dengan total <b>Rp. ${this.formatPrice(
          this.mergeData?.totalAmount
        )}</b>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok !",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) this.konfirmasi();
        else if (result.dismiss === Swal.DismissReason.cancel) {
          this.book.bankName = "";
          this.error1.selectBank1 = "";
        }
      });
    },
    getLocalStorage() {
      if (
        localStorage.getItem("schoolId") &&
        localStorage.getItem("schoolId") != "null"
      )
        this.schoolId = localStorage.getItem("schoolId")!;
      else this.schoolId = "";
    },
    submitBayarManual() {
      Swal.fire({
        title:
          "Apakah Anda Yakin akan menggabungkan invoices dan membayar manual?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ok !",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) this.konfirmasiManual();
        else if (result.dismiss === Swal.DismissReason.cancel) {
          this.book.bankName = "";
          this.error1.selectBank1 = "";
        }
      });
    },
    changeBank(event) {
      this.dataPayment.paymentId = "";
      this.dataPayment.paymentMethod = "";
      this.dataPayment.paymentStatus = "";
      this.dataPayment.vaName = "";
      this.dataPayment.vaBank = "";
      this.dataPayment.vaNumber = "";
      this.dataPayment.item = "";
      this.checkDataPayment = {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: "",
        },
      };
      this.book.metodeName = "Otomatis";
      if (this.joinInvoice) this.book.metodeName = "Manual";
      this.book.sumberdana = "";
      this.balanceCoa = 0;
      this.bankManual = "";
      this.targetKonfirmasi.rekTujuan = "";
      this.book.bankName = "";
      var modal = new Modal(document.getElementById("kt_modal_2"));
      modal.show();
    },
    bantuanModal() {
      var modal = new Modal(document.getElementById("kt_modal_4"));
      modal.show();
    },
    async konfirmasi() {
      try {
        this.isLoading = true;
        let response = await ApiService.postWithData(
          `crmv2/main_invoice/invoice/office/invoice/join_invoice/join`,
          { invoiceBase: this.mergeData, note: this.mergeData.note }
        );
        this.modalOpen = false;
        this.getData(this.paging.size, this.paging.page);
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async konfirmasiSplit(item) {
      try {
        this.isLoading = true;
        let response = await ApiService.getWithoutSlug(
          `crmv2/main_invoice/invoice/office/invoice/join_invoice/split?id=${item._id}`
        );
        this.$router.push("/superadmin/invoices");
        this.getData(this.paging.size, this.paging.page);
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    changebankManual(e) {
      this.listBankManual.forEach((element: any, idx) => {
        if (element.bankName === e.target.value) {
          this.targetKonfirmasi.rekTujuan = element.bankAccountNumber;
          this.targetKonfirmasi.namaRektujuan = element.bankAccountName;
          this.book.bankName = element.bankName;
        }
      });
      this.getTutorial(e.target.value);
    },
    konfirmasiManual() {
      this.isLoading = true;
      const toast = useToast();
      var today = new Date();
      var date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      this.book.addInvoices = {
        invoiceDate: date,
        detail: [],
      };
      var url = "crmv2/main_invoice/invoice/office/invoice";
      if (
        !(
          this.role == "SUPER_ADMIN" ||
          this.role == "SALES_AREA" ||
          this.role == "SALES_LEADER"
        )
      )
        url = "crmv2/main_invoice/invoice/invoice";
      var metod = this.book.metodeName == "Otomatis" ? "AUTOMATIC" : "MANUAL";
      this.book.addInvoices["detail"] = this.book.invoice.detail;
      ApiService.postWithData(
        `crmv2/main_invoice/invoice/office/invoice?schoolId=${this.schoolId}&title=Tagihan&itemType=Package`,
        this.book.addInvoices
      )
        .then((res) => {
          this.Idinvoice = res.data._id;
          this.targetKonfirmasi.id = res.data._id;
          ApiService.postWithoutSlug(
            `${url}/pay?bankName=${this.book.bankName}&invoiceId=${this.Idinvoice}&paymentMethod=${metod}`
          ).then((res3) => {
            this.showVaName = res3.data.vaName;
            this.showVaBank = res3.data.vaBank;
            this.targetKonfirmasi.namaRektujuan = res3.data.vaBank;
            this.targetKonfirmasi.rekTujuan = res3.data.vaNumber;
            this.targetKonfirmasi.paidAmount = res3.data.totalAmount;
            this.targetKonfirmasi.persetujuanFinance = res3.data.paymentStatus;
            this.targetKonfirmasi.paymentId = res3.data.paymentId;
            this.showVaNumber = res3.data.vaNumber;
            this.showExpVaNumber = res3.data.expiredDate;
            this.viewInvoices = res3.data;
            localStorage.setItem("listDetailInv", JSON.stringify(res3.data));
            this.targetKonfirmasi.paymentId = res3.data.paymentId;
            this.tipeBayarManualSingle = false;
            this.buttonBayarManualSingle = false;
            this.errorBayarManual.tanggal = "";
            this.errorBayarManual.namaPengirim = "";
            this.errorBayarManual.noRef = "";
            this.errorBayarManual.penanggungJawab = "";
            toast.success("invoice berhasil di gabungkan");
            this.resultBayarManual = false;
            this.targetKonfirmasi.noRef = "";
            this.targetKonfirmasi.tanggal = "";
            this.targetKonfirmasi.namaPengirim = "";
            this.targetKonfirmasi.penanggungJawab = "";
            const modal = new Modal(
              document.getElementById("kt_modal_konfirm_manual")
            );
            modal.show();
            this.isLoading = false;
          });
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    async saveModalFormKonfirmasi() {
      const toast = useToast();
      if (
        this.targetKonfirmasi.noRef === "" ||
        this.targetKonfirmasi.namaPengirim === "" ||
        this.targetKonfirmasi.penanggungJawab === "" ||
        this.targetKonfirmasi.imageBuktiTF === "" ||
        this.targetKonfirmasi.tanggal === "" ||
        this.targetKonfirmasi.paidAmount === 0
      ) {
        if (this.targetKonfirmasi.noRef === "")
          this.errorBayarManual.noRef = "Nomor Referensi Wajib Diisi";
        if (this.targetKonfirmasi.namaPengirim === "")
          this.errorBayarManual.namaPengirim =
            "Nama Rekening Pengirim Wajib Diisi";
        if (this.targetKonfirmasi.penanggungJawab === "")
          this.errorBayarManual.penanggungJawab =
            "Penanggung Jawab Wajib Diisi";
        if (this.targetKonfirmasi.tanggal === "")
          this.errorBayarManual.tanggal = "Tanggal Pembayaran Wajib Diisi";
        if (this.targetKonfirmasi.imageBuktiTF === "")
          this.errorBayarManual.imageBuktiTF = "File Tidak Boleh Kosong";
        toast.error("Form belum lengkap");
        if (this.targetKonfirmasi.paidAmount === 0)
          toast.error(
            "Untuk melakukan pembayaran nominal tagihan Tidak Boleh 0"
          );
        return false;
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("file", this.targetKonfirmasi.imageBuktiTF);
        let isi = {
          paymentId: this.targetKonfirmasi.paymentId,
          note: this.targetKonfirmasi.note,
          paymentDate: moment(this.targetKonfirmasi.tanggal).format(
            "YYYY-MM-DD"
          ),
          refNumber: this.targetKonfirmasi.noRef,
          paidAmount: this.targetKonfirmasi.paidAmount,
          bankNumbSender: "None",
          bankNameSender: this.targetKonfirmasi.namaPengirim,
          bankNumbTo: this.targetKonfirmasi.rekTujuan,
          bankNameTo: this.targetKonfirmasi.namaRektujuan,
          pic: this.targetKonfirmasi.penanggungJawab,
          imageReceipt: [""],
          status: "WAIT",
        };
        var url = `crmv2/main_invoice/invoice/office/`;
        if (this.role !== "SUPER_ADMIN") {
          url = `crmv2/main_invoice/invoice/`;
        }
        try {
          let resImage = await ApiService.postWithData(
            `crmv2/main_image/image/upload/${this.targetKonfirmasi.id}`,
            formData
          );
          isi.imageReceipt = [resImage.data.imgUrl];
          await ApiService.postWithData(
            `${url}invoice/confirm?invoiceId=${this.targetKonfirmasi.id}`,
            isi
          );
          toast.success("Sukses Bayar Manual");
          this.resultBayarManual = true;
          this.successBayarManual = true;
          const modal1 = new Modal(document.getElementById("kt_modal_2"));
          modal1.hide();
          this.book.metodeName = "Otomatis";
        } catch (error: any) {
          this.resultBayarManual = false;
          this.successBayarManual = false;
          toast.error("Gagal, " + error.response?.data?.detail);
        } finally {
          this.isLoading = false;
        }
      }
    },
    goToPageHistory() {
      this.$router.push("/superadmin/history-pembayaran-manual");
    },
    refreshData() {
      this.getData(this.paging.size, this.paging.page);
    },
    saveModalKonfirmasi() {
      const toast = useToast();
      ApiService.postWithoutSlug(
        `crmv2/main_invoice/invoice/update_manual_payment/${this.targetKonfirmasi.paymentId}?process=MANUAL&refNumber=${this.targetKonfirmasi.noRef}&pic=${this.targetKonfirmasi.penanggungJawab}`
      )
        .then((res3) => {
          toast.success("Sukses Bayar Manual");
          var modal2 = new Modal(
            document.getElementById("kt_modal_konfirm_manual_fix")
          );
          modal2.hide();
          this.$router.push("/superadmin/history-pembayaran-manual");
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          toast.error("Gagal Bayar Manual");
        });
    },
    cancelModalKonfirmasi() {
      var modal = new Modal(
        document.getElementById("kt_modal_konfirm_manual_fix")
      );
      modal.hide();
    },
    bayarManualSingle(e) {
      if (e == "Otomatis") {
        var modal = new Modal(document.getElementById("kt_modal_2"));
        modal.show();
        this.paymentByID();
      } else {
        this.tipeBayarManualSingle = true;
        this.buttonBayarManualSingle = false;
        this.errorBayarManual.namaPengirim = "";
        this.errorBayarManual.noRef = "";
        this.errorBayarManual.penanggungJawab = "";
        this.resultBayarManual = false;
        this.targetKonfirmasi.namaPengirim = "";
        this.targetKonfirmasi.noRef = "";
        this.targetKonfirmasi.penanggungJawab = "";
        this.targetKonfirmasi.namaRektujuan = this.returnAcontName(
          this.checkDataPayment.data?.vaBank
        );
        this.targetKonfirmasi.rekTujuan = this.returnBankName(
          this.checkDataPayment.data?.vaBank
        );
        var modal2 = new Modal(document.getElementById("kt_modal_2"));
        modal2.hide();
        const modal = new Modal(
          document.getElementById("kt_modal_konfirm_manual")
        );
        modal.show();
      }
    },
    batalFormKonfirmasi() {
      this.buttonBayarManualSingle = true;
      this.isLoading = false;
      this.clickReset();
    },
    TutupCancelKonfirmasi() {
      this.getData(this.paging.size, this.paging.page);
      var data = document.getElementsByClassName("checkHistory");
      this.book.detail = [];
      for (let index = 0; index < data.length; index++)
        if (data[index]["checked"] == true) data[index]["checked"] = false;
    },
    async paymentByID(isGenerate = false) {
      var metode = this.book.metodeName;
      const toast = useToast();
      this.book.metodeName = metode;
      if (!this.doSubmit) return;
      if (this.book.sumberdana == "")
        this.error2.selectBank2 = "Sumber Dana Harus Diisi";
      if (this.book.bankName == "")
        this.error1.selectBank1 = "Bank Harus Diisi";
      if (!isGenerate) {
        if (this.sumber == "Topup Manual" && this.book.bankName == "")
          return toast.error("Form Kosong wajib diisi");
        if (this.showFormCoa === true) this.book.sumberdana = "";
        if (
          this.sumber == "Topup Manual" &&
          this.balanceCoa < this.totalAmount &&
          !this.showFormCoa
        ) {
          var message = `Saldo Coa${this.book.sumberdana}tidak cukup untuk pembayaran invoice`;
          this.book.sumberdana = "";
          return toast.error(message);
        }
      }
      if (this.book.bankName == "")
        this.error1.selectBank1 = "Bank Harus Diisi";
      else {
        this.isLoading = true;
        const metod = metode == "Otomatis" ? "AUTOMATIC" : "MANUAL";
        var url = "crmv2/main_invoice/invoice/office/invoice/pay";
        if (this.role !== "SUPER_ADMIN")
          url = "crmv2/main_invoice/invoice/invoice/pay";
        url = `${url}?bankName=${this.book.bankName}&invoiceId=${this.Idinvoice}&paymentMethod=${metod}&coaCode=${this.book.sumberdana}`;
        try {
          let response = await ApiService.postWithoutSlug(url);
          var urlrole_cekpayment_ =
            this.role == "SUPER_ADMIN"
              ? "crmv2/main_invoice/invoice/office/invoice"
              : "crmv2/main_invoice/invoice/invoice";
          let responseData = await ApiService.getWithoutSlug(
            `${urlrole_cekpayment_}/payment_data/${this.Idinvoice}`
          );
          this.checkDataPayment = responseData.data;
          this.book.preview = response.data;
          this.dataPayment.paymentMethod = this.checkDataPayment.paymentMethod;
          this.dataPayment.paymentStatus = this.checkDataPayment.paymentStatus;
          if (metod == "MANUAL") {
            this.statusModalBayarSingleVa = true;
            this.showVaName = response.data.vaName;
            this.showVaBank = response.data.vaBank;
            this.targetKonfirmasi.paidAmount = response.data.totalAmount;
            this.targetKonfirmasi.persetujuanFinance =
              response.data.paymentStatus;
            this.targetKonfirmasi.paymentId = response.data.paymentId;
            this.showVaNumber = response.data.vaNumber;
            this.showExpVaNumber = response.data.expiredDate;
          } else {
            this.previewPembayaranSingle = false;
            this.dataPayment.vaName = this.checkDataPayment.data?.vaName || "";
            this.dataPayment.vaBank = this.checkDataPayment.data?.vaBank || "";
            this.dataPayment.vaNumber =
              this.checkDataPayment.data?.vaNumber || "";
            this.showExpVaNumber = response.data.expiredDate;
            this.expDate =
              new Date(response.data.expiredDate).getTime() - Date.now();
            toast.success(this.alertChange);
          }
        } catch (error) {
        } finally {
          this.isLoading = false;
          this.error1.selectBank1 = "";
          this.error2.selectBank2 = "";
        }
      }
    },
    returnBankName(e) {
      var data = "";
      this.listBank.forEach((element: any) => {
        if (element.bankName === e) data = element.bankAccountNumber;
      });
      return data;
    },
    returnAcontName(e) {
      var data = "";
      this.listBank.forEach((element: any) => {
        if (element.bankName === e) data = element.bankAccountName;
      });
      return data;
    },
    getInvoicesById() {
      const toast = useToast();
      this.isLoading = true;
      ApiService.getWithoutSlug(
        `crmv2/main_invoice/invoice/invoice/${this.Idinvoice}`
      )
        .then((response) => {
          this.InvoicesById = response.data;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          toast.error(response.data.detail);
          this.isLoading = false;
        });
    },
    getcombocoa(val) {
      if (val) {
        ApiService.getWithoutSlug(
          `crmv2/main_invoice/invoice/office/invoice/coa/combo_coa/${val}`
        ).then(({ data }) => {
          this.listcoa = data;
        });
      }
    },
    getbalance(val) {
      const toast = useToast();
      ApiService.getWithoutSlug(
        `crmv2/main_invoice/invoice/office/invoice/coa/balance/${this.schoolid2}?coaCode=${val}`
      )
        .then(({ data }) => {
          if (data.balance < this.totalAmount && !this.showFormCoa) {
            var message = `Saldo Coa ${this.book.sumberdana} tidak cukup untuk pembayaran invoice`;
            this.book.sumberdana = "";
            this.balanceCoa = 0;
            return toast.error(message);
          }
          this.balanceCoa = data.balance;
        })

        .catch((error) => {});
    },
    cari(val) {
      clearTimeout(this.timerComboSchool); //this timer 0 - set 0 atau clear jika ada input lagi
      this.loadingComboSchool = true;
      this.timerComboSchool = setTimeout(() => {
        this.getListSchool(val);
      }, 1000);
    },
    getListSchool(name = "") {
      this.loadingComboSchool = true;
      ApiService.getWithoutSlug(
        `crmv2/main_invoice/invoice/office/invoice/combo/school?name=${name}`
      ).then(({ data }) => {
        this.loadingComboSchool = false;
        this.ListSchool = data;
      });
    },
    getResume() {
      let schoolId = "";
      if (this.schoolId) schoolId = `schoolId=${this.schoolId}&`;
      let query: any = [];
      query.push(`schoolId=${this.schoolId || ""}`);
      query.push(`title=${this.title || ""}`);
      query.push(`itemType=${this.itemType || ""}`);
      query.push(`number=${this.number || ""}`);
      query.push(`invoiceYear=${this.invoiceYear || ""}`);
      query.push(`paymentStatus=${this.paymentStatus || ""}`);
      if (this.province.length > 0)
        query.push(`province=${this.province.join(",") || ""}`);
      if (this.salesName != "")
        query.push(`salesAreaId=${this.salesName || ""}`);
      if (this.invoiceType != "")
        query.push(`itemType=${this.invoiceType || ""}`);
      query.push(`invoiceNumber=${this.invoiceNumber || ""}`);
      ApiService.getWithoutSlug(
        `crmv2/main_invoice/invoice/invoice/finance/resume?${query.join("&")}`
      )
        .then(({ data }) => {
          this.resume = { ...data };
        })
        .catch(({ response }) => {});
    },
    getData(size, page) {
      this.isLoading = true;
      let query: any = [];
      query.push(`schoolId=${this.schoolId || ""}`);
      query.push(`title=${this.title || ""}`);
      query.push(`itemType=${this.itemType || ""}`);
      query.push(`number=${this.number || ""}`);
      query.push(`invoiceYear=${this.invoiceYear || ""}`);
      query.push(`paymentStatus=${this.paymentStatus || ""}`);
      if (this.province.length > 0)
        query.push(`province=${this.province.join(",") || ""}`);
      if (this.salesName != "")
        query.push(`salesAreaId=${this.salesName || ""}`);
      if (this.invoiceType != "")
        query.push(`itemType=${this.invoiceType || ""}`);
      query.push(`invoiceNumber=${this.invoiceNumber || ""}`);
      query.push(`page=${page}`);
      query.push(`size=${size}`);
      query.push(`sort=updateTime`);
      query.push(`dir=-1`);
      ApiService.getWithoutSlug(
        `crmv2/main_invoice/invoice/office/invoice/all?${query.join("&")}`
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          ApiService.getWithoutSlug(
            `crmv2/main_invoice/invoice/invoice/finance/resume?${query.join(
              "&"
            )}`
          ).then(({ data }) => {
            this.resume = { ...data };
            this.isLoading = false;
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    deleteRow(item) {
      const toast = useToast();
      ApiService.delete(`crmv2/main_invoice/invoice/office/invoice/${item._id}`)
        .then((res) => {
          toast.success("Hapus Berhasil");
          this.getData(this.paging.size, this.paging.page);
        })
        .catch((error) => {
          toast.error("Gagal Hapus");
        });
    },
    async handleSelectedAll() {
      if (this.selectAll) {
        this.selected = [];
        this.selectedId = [];
      } else {
        this.selected = [...this.previewDatas];
        this.selectedId = this.previewDatas.map((el: any) => el._id);
      }
      this.selectAll = !this.selectAll;
      if (this.selectAll) this.getPreviewData();
    },
    async handleSelected(index, item) {
      let findI = this.selected.findIndex((el: any) => el._id == item._id);
      if (findI > -1) {
        this.selected.splice(findI, 1);
        this.selectedId.splice(findI, 1);
      } else {
        this.selected.push(item);
        this.selectedId.push(item._id);
      }
      this.getPreviewData();
      this.selectAll = this.previewDatas.length == this.selected.length;
    },
    async getPreviewData() {
      this.resetMergeData();
      if (this.previewDatas.length < 1) return;
      try {
        let responsePreview = await ApiService.postWithData(
          `crmv2/main_invoice/invoice/office/invoice/join_invoice/preview`,
          { invoiceIds: this.selectedId }
        );

        this.wordCurrency = responsePreview.data.wordCurrency;
        this.totalAmount = responsePreview.data.totalAmount;
        this.discount = responsePreview.data.discount;
        this.tax = responsePreview.data.tax;
        this.shipping = responsePreview.data.shipping;
        this.amount = responsePreview.data.amount;
        this.mergeData = responsePreview.data;
      } catch (error) {}
    },
    async bayarPerInvoiceId(item) {
      this.previewPembayaranSingle = false;
      if (item.paymentStatus == "UNPAID") {
        if (item.vaNumber) {
          this.statusModalBayarSinglePaid = false;
          if (new Date().getTime() <= new Date(item.expiredDate).getTime()) {
            this.book.bankName = "";
            this.statusModalBayarSingleVa = true;
            this.book.metodeName = "Otomatis";
            this.showVaName = item.vaName;
            this.showVaBank = item.vaBank;
            this.book.bankName = item.vaBank;
            this.targetKonfirmasi.paidAmount = item.totalAmount;
            this.showVaNumber = item.vaNumber;
            this.showExpVaNumber = item.expiredDate;
            this.expDate = new Date(item.expiredDate).getTime() - Date.now();
          } else this.statusModalBayarSingleVa = false;
        } else {
          this.statusModalBayarSinglePaid = false;
          this.statusModalBayarSingleVa = false;
        }
      } else {
        this.statusModalBayarSingleVa = false;
        this.statusModalBayarSinglePaid = true;
      }
      this.Idinvoice = item._id;
      this.targetKonfirmasi.id = item._id;
      this.targetKonfirmasi.paymentId = item.paymentId;
    },
    handleDownload() {
      const toast = useToast();
      let query: any = [];
      query.push(`schoolId=${this.schoolId || ""}`);
      query.push(`title=${this.title || ""}`);
      query.push(`itemType=${this.itemType || ""}`);
      query.push(`number=${this.number || ""}`);
      query.push(`invoiceYear=${this.invoiceYear || ""}`);
      query.push(`paymentStatus=${this.paymentStatus || ""}`);
      if (this.province.length > 0)
        query.push(`province=${this.province.join(",") || ""}`);
      if (this.salesName != "")
        query.push(`salesAreaId=${this.salesName || ""}`);
      if (this.invoiceType != "")
        query.push(`itemType=${this.invoiceType || ""}`);
      query.push(`invoiceNumber=${this.invoiceNumber || ""}`);
      ApiService.getDownload(
        `crmv2/main_invoice/invoice/office/invoice/download/all_invoice.xlsx?${query.join(
          "&"
        )}`
      )
        .then((res) => {
          this.handleBlobResponse(res.data, "Data Tagihan", "xlsx");
        })
        .catch((e) => {
          toast.error(e.res.data.detail);
        });
    },
    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) this.deleteRow(item);
      });
    },
    handleSplit(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        html: `Data invoice <b>${item.title}</b> akan dipisah !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya!",
        cancelButtonText: "Batal",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) this.konfirmasiSplit(item);
      });
    },
    selectBank() {
      if (this.book.bankName == "") {
        document
          .getElementById("submitAddInvoices")
          ?.setAttribute("disabled", "disabled");
        document
          .getElementById("submitBayarManual")
          ?.setAttribute("disabled", "disabled");
      } else {
        document
          .getElementById("submitAddInvoices")
          ?.removeAttribute("disabled");
        document
          .getElementById("submitBayarManual")
          ?.removeAttribute("disabled");
      }
    },
    gantiStatus(val) {
      this.paymentStatus = val;
      this.getData(this.paging.size, this.paging.page);
    },
    pilihSekolah(event) {
      var namaSekolah = "";
      this.schoolId = "";
      this.schoolSelected = false;
      localStorage.removeItem("sekolahIdMenuInvoice");
      if (event) {
        this.schoolId = event;
        this.getcombocoa(event);
        this.schoolSelected = true;
        localStorage.setItem("sekolahIdMenuInvoice", event);
        let school: any = this.ListSchool.find(
          (item: any) => item.id == this.schoolId
        );
        namaSekolah = school.name;
        this.schoolName = school.name;
      }
      localStorage.setItem("schoolName", namaSekolah);
      localStorage.setItem("sekolahId", this.schoolId);
      if (this.modalOpen) {
        this.previewData();
      } else this.getData(this.paging.size, this.paging.page);

      var data = document.getElementsByClassName("checkHistory");
      this.book.detail = [];

      for (let index = 0; index < data.length; index++) {
        if (data[index]["checked"] == true) {
          data[index]["checked"] = false;
        }
      }
    },
    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) arr.push(i);
      return arr;
    },
    changeDate(date) {
      return moment(date, "yyyy-mm-dd").format("dd-mm-yyyy");
    },
    goBack() {
      this.konfirm = true;
      this.getData(this.paging.size, this.paging.page);
    },
    clickReset() {
      this.getData(this.paging.size, this.paging.page);
      this.book.invoice = [];
      this.book.bankName = "";
      this.book.sumberdana = "";
      this.error1.selectBank1 = "";
      this.error2.selectBank2 = "";
      this.totalAmount = 0;
      this.book.nomorRekening = "";
      this.bankManual = "";
      this.alertChange = "Metode Pembayaran Berhasil Dipilih";
      this.paymentStatusInit = false;
      this.balanceCoa = 0;
      this.dataPayment = {
        paymentId: "",
        paymentMethod: "",
        paymentStatus: "",
        vaName: "",
        vaBank: "",
        showExpVaNumber: "",
        vaNumber: "",
        item: "",
      };
      this.checkDataPayment = {
        data: {
          callbacks: [{ coaCode: "" }],
          vaBank: "",
        },
      };
      this.book.metodeName = "Otomatis";
      this.book.sumberdana = "";
      this.showFormCoa = false;
      this.balanceCoa = 0;
      this.bankManual = "";
      this.targetKonfirmasi.rekTujuan = "";
      this.book.bankName = "";
      this.targetKonfirmasi = {
        id: "",
        namaPengirim: "",
        rekTujuan: "",
        persetujuanFinance: "",
        rekPengirim: "",
        tanggal: "",
        note: "",
        paymentId: "",
        noRef: "",
        penanggungJawab: "",
        namaRektujuan: "",
        paidAmount: 0,
        imageBuktiTF: "",
        imageBuktiTFRslt: "",
      };
      this.expDate = 0;
      this.showExpVaNumber = "";
      this.Idinvoice = "";
      this.modalOpen = false;
      this.joinInvoice = false;
    },
    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    checkStatus(val) {
      if (val == "PAID") return false;
      else return true;
    },
    onChangeBank(event) {
      this.companyId = "";
      if (!event) return;
      this.companyId = event;
      localStorage.setItem("companyId", JSON.stringify(event));
    },
    handleFotoImageBuktiTF(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        if (
          myArray[1] == "jpg" ||
          myArray[1] == "jpeg" ||
          myArray[1] == "png"
        ) {
          this.imageBuktiTFDisplay = URL.createObjectURL(event.target.files[0]);
          this.targetKonfirmasi.imageBuktiTF = event.target.files[0];
          this.errorBayarManual.imageBuktiTF = "";
        } else {
          toast.error("File Hanya .jpg .jpeg .png");
          this.fileInputKey3++;
          this.errorBayarManual.imageBuktiTF = "File Hanya .jpg .jpeg .png";
          this.targetKonfirmasi.imageBuktiTF = "";
        }
      } else {
        toast.error("File Kosong");
        this.errorBayarManual.imageBuktiTF = "File Tidak Boleh Kosong";
        this.targetKonfirmasi.imageBuktiTF = "";
        this.fileInputKey3++;
      }
    },
    formatDate(value) {
      return moment(value).format("DD MMMM YYYY");
    },
    formatDateTime(value) {
      return moment(value).format("DD MMMM YYYY HH:mm");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMinustoPositive(value) {
      let result = value;
      if (value < 0) result = Math.abs(value);
      return result;
    },
    formatTittle(value) {
      let cek = value.includes("TANGGAL");
      if (cek !== true) return value;
      const myArray = value.split("TANGGAL");
      return myArray[0];
    },
    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-" + dd;
      return rslt;
    },
    getDateFirst() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-01";
      return rslt;
    },
    pilihTanggalAll() {
      this.startDate = "";
      this.endDate = "";
      if (this.dateAll) {
        this.startDate = this.dateAll[0];
        this.endDate = this.dateAll[1];
      }

      localStorage.setItem("dateAll", JSON.stringify(this.dateAll));
      localStorage.setItem("tanggalAwal", JSON.stringify(this.startDate));
      localStorage.setItem("tanggalAkhir", JSON.stringify(this.endDate));
      this.previewData();
    },
    eventDelaySearch(e) {
      clearTimeout(this.timer); //this timer 0 - set 0 atau clear jika ada input lagi
      this.timer = setTimeout(() => {
        const page = this.paging.page;
        this.getData(this.paging.size, page);
      }, 1000);
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
    openModalBantuan() {
      var modal = new Modal(document.getElementById("kt_modal_bantuan"));
      modal.show();
    },
    openModalDownload() {
      var modalInvoice = new Modal(
        document.getElementById("invoice_download_modal")
      );
      modalInvoice.show();
    },
    download() {
      const toast = useToast();
      let query: any = [];
      query.push(`schoolId=${this.schoolId || ""}`);
      if (this.paymentStatus) query.push(`paymentStatus=${this.paymentStatus}`);
      ApiService.getDownload(
        `crmv2/main_invoice/invoice/office/invoice/all/download?${query.join(
          "&"
        )}`
      )
        .then((res) => {
          this.handleBlobResponse(res.data, "Data Tagihan", "xlsx");
        })
        .catch((e) => {
          toast.error("");
        });
    },
    handleBlobResponse(blobFileData, newNama, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", newNama + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },
    getComboInvoiceType() {
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/coa/combo/invoice_type"
      )
        .then((res) => {
          this.listInvoiceType = res.data;
        })
        .catch((error) => {});
    },
    getComboProvince() {
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/combo/province"
      )
        .then((res) => {
          this.listProvince = res.data;
        })
        .catch((error) => {});
    },
    getComboSales() {
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/combo/sales"
      )
        .then((res) => {
          this.listSalesName = res.data;
        })
        .catch((error) => {});
    },
    pilihProvince(event) {
      this.province = event;
      this.getData(this.paging.size, this.paging.page);
    },
    pilihInvoiceType(event) {
      this.invoiceType = event;
      this.getData(this.paging.size, this.paging.page);
    },
    pilihSales(event) {
      this.salesName = event;
      this.getData(this.paging.size, this.paging.page);
    },
  },
});
